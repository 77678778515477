.magSet {
  // position: "relative" !important;
  left: "125px" !important;
}
.mainDivBook {
  background-color: #f1f1f1;
  width: 100%;
  height: 800px;
  margin-top: 100px;
}
.madeEasy {
  color: #1f1f95;
  font-weight: bold;
  font-size: 23.95px;
  text-align: center;
  font-family: "Raleway", sans-serif;
  padding: 50px 0px 10px 0px;
  line-height: 32px;
}
.paddingClass {
  padding-left: 36px;
}
.bookParagraph {
  text-align: center;
  letter-spacing: 0.2px;
  padding: 0px 190px;
}
.paragraph {
  line-height: 30px;
  color: gray;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
}
.booksDiv {
  margin: 20px;
  margin-top: 70px;
}
.book1 {
  width: 200px;
  height: 250px;
}
.conceptHandbook {
  color: #077c07;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.bookDetail1 {
  color: gray;
  text-align: left;
  width: 95%;
  line-height: 27px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
.shadowImage {
  width: 390px;
  position: relative;
  right: 200px;
  bottom: 30px;
}
@media only screen and (max-width: 360px) {
  .madeEasy {
    color: #1f1f95;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    margin-top: 20px;
    padding-top: 50px;
  }
  .showBook {
    display: none;
  }
  .mainDivBook {
    background-color: #f1f1f1;
    width: 100%;
    height: 650px;
    margin-top: 30px;
  }
  .centerBookImg {
    margin-top: -80px;
    border: 1px solid #777;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  .bookDetail1 {
    color: gray;
    text-align: left;
    width: 96%;
    position: absolute;
    left: 10px !important;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 14px !important;
    bottom: -4px;
  }
  .book1 {
    width: 200px;
    height: 250px;
    margin-top: -100px;
  }
}
@media only screen and (max-width: 1000px) {
  .madeEasy {
    color: #1f1f95;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    margin-top: 20px;
    padding-top: 50px;
  }
  .showBook {
    display: none;
  }
  .mainDivBook {
    background-color: #f1f1f1;
    width: 100%;
    height: 650px;
    margin-top: 30px;
  }
  .centerBookImg {
    margin-top: -80px;
    border: 1px solid #777;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  .bookDetail1 {
    color: gray;
    text-align: left;
    width: 96%;
    position: absolute;
    left: 10px !important;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 14px !important;
    bottom: -4px;
  }
  .book1 {
    width: 200px;
    height: 250px;
    margin-top: -100px;
  }
}
