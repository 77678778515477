.testimonials {
  width: 100%;
  height: 500px;
}
.whatSay {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 37px;
}
.boxArea {
  border: 1px solid gray;
  width: 90%;
  height: 270px;
  position: relative;
  left: 70px;
  border-radius: 6px;
}
.boxArea2 {
  width: 95%;
  height: 248px;
  position: relative;
  border-radius: 6px;
  margin: 10px;
  background-color: #ded6d6;
}
.marks {
  background-color: #077c07;
  color: #fff;
  width: 75%;
  height: 42px;
  border: none;
  position: relative;
  top: 20px;
  left: 39px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}
.bottomTesti {
  position: relative;
  left: 70px;
  width: 90%;
  height: 90px;
  border-bottom: 1px solid #c3baba;
}
.studentName {
  font-size: 16px;
  text-align: left;
  color: #1313a1;
  font-family: "Nunito", sans-serif;
}
.batchName {
  font-size: 16px;
  text-align: left;
  font-family: "Nunito", sans-serif;
}
@media only screen and (max-width: 360px) {
  .testimonials {
    width: 100%;
    height: auto;
  }
  .boxArea2 {
    width: 95%;
    height: 128px;
    position: relative;
    border-radius: 6px;
    margin: 10px;
    background-color: #ded6d6;
  }
  .whatSay {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
  .whatFont {
    font-size: 18px;
  }
  .studentRating1 {
    margin-left: -63px;
    padding: 0px;
  }
  .studentRating2 {
    display: none;
  }
  .studentRating3 {
    display: none;
  }
  .boxArea {
    border: 1px solid gray;
    width: 80%;
    height: 147px;
    position: relative;
    left: 21px;
    border-radius: 6px;
  }
  .marks {
    background-color: #077c07;
    color: #fff;
    width: 140%;
    height: 42px;
    border: none;
    position: relative;
    top: 20px;
    left: 7px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .studentName {
    font-size: 14px;
    text-align: left;
    color: #1313a1;
    font-family: "Nunito", sans-serif;
  }
  .batchName {
    font-size: 14px;
    text-align: left;
    font-family: "Nunito", sans-serif;
  }
  .bottomTesti {
    position: relative;
    left: 21px;
    width: 80%;
    height: 90px;
    border-bottom: 1px solid #c3baba;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 1000px) {
  .testimonials {
    width: 100%;
    height: auto;
  }
  .boxArea2 {
    width: 95%;
    height: 128px;
    position: relative;
    border-radius: 6px;
    margin: 10px;
    background-color: #ded6d6;
  }
  .whatSay {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
  .whatFont {
    font-size: 18px;
  }
  .studentRating1 {
    margin-left: -63px;
    padding: 0px;
  }
  .studentRating2 {
    display: none;
  }
  .studentRating3 {
    display: none;
  }
  .boxArea {
    border: 1px solid gray;
    width: 80%;
    height: 147px;
    position: relative;
    left: 21px;
    border-radius: 6px;
  }
  .marks {
    background-color: #077c07;
    color: #fff;
    width: 140%;
    height: 42px;
    border: none;
    position: relative;
    top: 20px;
    left: 7px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .studentName {
    font-size: 14px;
    text-align: left;
    color: #1313a1;
    font-family: "Nunito", sans-serif;
  }
  .batchName {
    font-size: 14px;
    text-align: left;
    font-family: "Nunito", sans-serif;
  }
  .bottomTesti {
    position: relative;
    left: 21px;
    width: 80%;
    height: 90px;
    border-bottom: 1px solid #c3baba;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width:820px) and (max-width: 1180px) {
  .testimonials {
    width: 100%;
    height: auto;
  }
  .boxArea2 {
    width: 95%;
    height: 128px;
    position: relative;
    border-radius: 6px;
    margin: 10px;
    background-color: #ded6d6;
  }
  .whatSay {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
  .whatFont {
    font-size: 18px;
  }
  .studentRating1 {
    margin-left: -63px;
    padding: 0px;
  }
  .studentRating2 {
    display: none;
  }
  .studentRating3 {
    display: none;
  }
  .boxArea {
    border: 1px solid gray;
    width: 80%;
    height: 147px;
    position: relative;
    left: 21px;
    border-radius: 6px;
  }
  .marks {
    background-color: #077c07;
    color: #fff;
    width: 140%;
    height: 42px;
    border: none;
    position: relative;
    top: 20px;
    left: 7px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .studentName {
    font-size: 14px;
    text-align: left;
    color: #1313a1;
    font-family: "Nunito", sans-serif;
  }
  .batchName {
    font-size: 14px;
    text-align: left;
    font-family: "Nunito", sans-serif;
  }
  .bottomTesti {
    position: relative;
    left: 21px;
    width: 80%;
    height: 90px;
    border-bottom: 1px solid #c3baba;
    display: flex;
    flex-direction: row;
  }
}
