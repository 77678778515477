.mainHeadDisplay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.headerBack {
  background-color: #fff;
  height: 100px;
}

.login {
  font-family: "Nunito", sans-serif;
  margin: 0px 10px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 20px;
}

.register {
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  border: none;
  margin: 0px 10px;
  border-radius: 5px;
  background-color: #f58b40;
  color: #fff;
  width: 200px;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  padding: 13px 10px !important;
  margin-top: 16px;
}

.sideBar {
  background-color: #fff;
  width: 31%;
  height: 100%;
  position: fixed;
  right: 0px;
  z-index: 9999999;
  border-radius: 10px 0px 0px 10px;
}

.overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  z-index: 9;
  border-radius: 10px 0px 0px 10px;
  opacity: 0.5;
  overflow-y: hidden;
}

.removeIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: 30px;
  cursor: pointer;
}

.sectionReg {
  position: absolute;
  top: 65px;
  left: 35px;
}

.signUp {
  color: #3a519d;
  font-weight: 700;
  font-family: "Roboto";
  font-size: 48px;
  line-height: 56.25px;
  text-align: left;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 2;
  opacity: 0.7;
}

.name {
  width: 380px;
  border: 1px solid #eeecec;
}

.bottomSpace {
  margin-bottom: 20px;
  position: relative;
}

.notifWrap {
  background: red;
  padding: 7px 10px;
  border-radius: 5px;
  text-align: center;
  width: 85%;
  margin-left: 39px;
  margin-top: 100px;
  -webkit-box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.notifWrapReg {
  background: red;
  padding: 7px 10px;
  border-radius: 5px;
  text-align: center;
  width: 60%;
  margin-left: 39px;
  margin-top: 41px;
  -webkit-box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.bottomSpaceBtn {
  margin-bottom: 20px;
  position: relative;
}

.closeEyeIcon {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.eyeIcon {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.signinBtn {
  width: 100%;
  height: 40px;
  font-size: 1px;
  font-weight: 600;
  background-color: #3a519d;
  font-family: "Roboto";
}

.alreadyUser {
  cursor: pointer;
  text-align: right;
  color: "#2367EB";
}

.sectionLog {
  position: absolute;
  top: 170px;
  left: 35px;
}

.bottomSpaceBtnLogin {
  position: relative;
}

.bottomSpace2 {
  margin-bottom: 40px;
  position: relative;
}

.eyeIconLogin {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.mainDiv {
  position: absolute;
  top: 55px;
}

.selectedTypes {
  /* font-family: "Nunito", sans-serif;
  position: relative;
  top: 0px;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  background-color: #3a519d;
  border: none;
  padding-bottom: 7px;
  border: 1px solid #e0e0e0;
  color: #fff;
  border-radius: 5px;
  margin: 6px; */
}

.franchisee1536 {
  position: relative;
  top: 7px;
  right: 120px;
}

.franchisee19 {
  position: relative;
  top: 10px;
  right: 50px;
}

.arrowHexHeader {
  position: relative;
  top: 10px;
  font-size: 20px;
  left: 3px;
}

.dropdownall {
  width: 200px;
  height: auto;
  background-color: #f0f1f6;
  position: absolute;
  top: 43px;
  z-index: 99;
  text-align: left;
}

.dropdownall1396 {
  width: 200px;
  height: auto;
  background-color: #f0f1f6;
  position: absolute;
  top: 43px;
  z-index: 99;
  text-align: left;
}

.categoryArea {
  border-right: 1px solid #d8cfcf;
  height: auto;
}

.catSelected1396 {
  width: 200px;
  height: 50px;
  background-color: #3a519d;
  position: relative;
  right: 30px;
  color: #fff;
  border: 1px solid #b0abab;
}

.catSelected {
  width: 200px;
  height: 50px;
  background-color: #3a519d;
  position: relative;
  color: #fff;
  border: 1px solid #b0abab;
}

.colorDiv {
  background-color: #fff !important;
  background: #fff !important;
  color: #000;
  cursor: pointer;
}

.colorDiv h5 {
  font-size: 15px;
}

.colorDiv h5:hover {
  font-size: 15px;
  font-weight: 900;
}

.colorDiv:hover {
  color: #3a519d !important;
  font-size: 16px !important;
  cursor: pointer;
}

.cat1396 {
  width: 200px;
  height: 50px;
  background-color: #fff;
  position: relative;
  color: #000;
  border: 1px solid #b0abab;
  border-width: 80%;
}

.cat {
  width: 200px;
  height: 50px;
  background-color: #fff;
  position: relative;
  color: #000;
  border: 1px solid #b0abab;
}

.cat h5 {
  font-size: 15px;
}

.cat h5:hover {
  font-size: 15px;
  font-weight: 900;
}

.cat:hover {
  width: 200px;
  height: 50px;
  background-color: #c3c9d3;
  position: relative;
  color: #3a519d;
  border: 1px solid #b0abab;
  font-weight: 900;
  font-size: 15px;
}

.arrowHexCode {
  position: relative;
  top: 2px;
  left: 90px;
  font-size: 25px;
}

.catName {
  position: relative;
  top: 10px;
  width: 98%;
  height: 30px;
  border-bottom: 1px solid #d6cfcf;
  border-width: 80%;
}

.streamsArea {
  position: relative;
  top: 10px;
  left: 12px;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
}

.streamsArea1396 {
  position: relative;
  top: 10px;
  left: 12px;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
}

.streams {
  position: relative;
  right: 28px;
  top: 59px;
}

.contact {
  display: none;
}

.contact1 {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 8px;
  font-family: "Nunito", sans-serif;
  background-color: #3a519d;
  color: #fff;
  font-size: 18px;
}

@media only screen and (min-width: 1700px) {
  .logo {
    width: 252px;
    height: 78px;
    position: relative;
    right: 30px;
    bottom: 10px;
  }
  .selectedTypes {
    font-family: "Nunito", sans-serif;
    position: relative;
    top: 0px;
    font-weight: 900;
    font-size: 12px;
    cursor: pointer;
    background-color: #3a519d;
    border: none;
    padding-bottom: 7px;
    color: #fff;
    border-radius: 5px;
  }
  .types {
    font-family: "Nunito", sans-serif;
    position: relative;
    top: 0px;
    font-weight: 900;
    font-size: 12px;
    cursor: pointer;
    border: none;
    padding-bottom: 7px;
    background-color: #fff;
    left: 10px;
  }
  .catSelected {
    width: 200px;
    height: 50px;
    background-color: #3a519d;
    position: relative;
    right: 30px;
    color: #fff;
    border: 1px solid #b0abab;
  }
  .cat {
    width: 200px;
    height: 50px;
    background-color: #f0f1f6;
    position: relative;
    right: 30px;
    color: #000;
    border: 1px solid #b0abab;
  }
  .streamsArea {
    position: relative;
    top: 10px;
    left: 12px;
    width: 100%;
    height: auto;
    padding-bottom: 60px;
    font-size: 14px !important;
  }
}

.selectTag {
  width: 100%;
  border: 1px solid #0000003b;
  padding: 10px;
  border-radius: 4px;
  outline: none;
}

.Web {
  display: block;
}

.Responsive {
  display: none;
}

.NewHeader {
  width: 100%;
  background-color: #fff;
}

.NewHeader .mainWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 58px;
}

.NewHeader .mainWrap p {
  padding: 5px 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.NewHeader .headerTopHead {
  background-color: #3a519d;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 4px 8px;
  text-align: center;
}

.NewHeader .headerTopHead p {
  margin: 0;
}

.NewHeader .logoAK {
  width: 100%;
  height: 80px;
}

.NewHeader .secondWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.NewHeader .selectedTypes {
  background-color: #3a519d;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  border: none;
  border: 1px solid #e0e0e0;
  margin: 8px 10px;
  border-radius: 5px;
  height: 48px;
  margin-top: 16px;
}

.NewHeader .types {
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  margin: 8px 10px;
  border-radius: 5px;
  height: 48px;
  margin-top: 16px;
}

.NewHeader .dropdownall {
  /* width: 200px;
  height: auto;
  background-color: #f0f1f6;
  position: absolute;
  top: 100px;
  z-index: 99;
  left: 23%;
  text-align: left; */
  width: 200px;
  height: auto;
  background-color: #f0f1f6;
  position: absolute;
  top: 124px;
  z-index: 99;
  left: 330px;
  text-align: left;
}

.NewHeader ._show {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: .5s;
  transition: .5s;
}

.NewHeader ._hide {
  -webkit-transform: translateX(500px);
          transform: translateX(500px);
  -webkit-transition: .5s;
  transition: .5s;
}

.NewHeader ._showNotif {
  -webkit-transform: translateY(-14px);
          transform: translateY(-14px);
  -webkit-transition: .5s;
  transition: .5s;
}

@media screen and (max-width: 767px) {
  .Web {
    display: none !important;
  }
  .Responsive {
    display: block !important;
  }
  .notifWrap {
    background: red;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    width: 97%;
    margin-left: 7px;
    margin-top: 30px;
    -webkit-box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .notifWrapReg {
    background: red;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    width: 97%;
    margin-left: 7px;
    margin-top: 30px;
    -webkit-box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .respInput {
    width: 87%;
  }
  .sectionReg {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .sideBar {
    background-color: #fff;
    width: 100%;
    height: 99%;
    position: fixed;
    z-index: 9999999;
    border-radius: 10px 10px 10px 10px;
  }
  .bottomSpaceBtn {
    margin-bottom: 15px;
  }
  .sectionLog {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .signUp {
    color: #3a519d;
    font-weight: 700;
    font-family: "Roboto";
    font-size: 30px;
    line-height: 56.25px;
    text-align: left;
  }
  .removeIcon {
    position: relative;
    width: 35px;
    height: 35px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    margin-left: 320px;
    margin-top: 10px;
  }
  .name {
    width: 320px;
    border: 1px solid #eeecec;
  }
  .NewHeader .logoAK {
    margin-bottom: -2px;
    z-index: 999;
    margin-top: 1em;
    margin-left: -31px;
    width: 100%;
    height: 58px !important;
  }
  .NewHeader .headerTopHead {
    background-color: #3a519d;
    color: #fff;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 2px 8px;
    text-align: center;
    height: 40px;
  }
  .contact1 {
    display: none;
  }
  .contact {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    padding: 8px;
    font-family: "Nunito", sans-serif;
    background-color: #3a519d;
    color: #fff;
    font-size: 14px;
  }
  .logo {
    width: 200px;
    height: 60px;
    margin-bottom: 10px;
    z-index: 999;
    margin-top: 2em;
    margin-left: -31px;
  }
  .headerBack {
    display: none;
  }
  .navHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 45px;
  }
  .menu {
    border: 1px solid #878787;
    border-radius: 5px;
    height: 40px;
    width: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    font-family: "Nunito", sans-serif;
    font-weight: 900;
    margin-top: 13px;
  }
  .menu .menuImg {
    margin-left: 18px;
  }
  .RespHeader {
    background-color: #ddd;
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 9999999999;
    overflow-y: hidden;
  }
  .cancelIcon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .nextIcon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
  }
  .secondDivNavbar p {
    border-bottom: 1px solid #c0bcbc;
    padding: 5px 10px;
  }
  .backBtn {
    border-bottom: 0.5px solid #3a519d;
    padding: 5px 10px;
  }
  .slideFromRight {
    -webkit-animation: slideFromRight 0.2s linear;
            animation: slideFromRight 0.2s linear;
    -webkit-transition: 1s linear ease-in;
    transition: 1s linear ease-in;
  }
  @-webkit-keyframes slideFromRight {
    0% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideFromRight {
    0% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  .closeEyeIcon {
    right: 20px;
  }
  .selectTag {
    width: 320px;
  }
}
