.joinNow {
  width: 100%;
  height: 420px;
  margin-top: 100px;
}
.ongoing {
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
}
.methodology {
  text-align: left;
  width: 73%;
  color: #8d8989;
  line-height: 30px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
.daysDivJoin {
}
.daysJoin {
  text-align: left;
  font-size: 18px;
  padding-bottom: 10px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
.joinDiv {
}
.joinn {
  width: 25%;
  height: 60px;
  border: 2px solid #92dbe4;
  border-radius: 5px;
  background-color: #fff;
  color: blue;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
.batchname {
  color: #1313a1;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 25px;
}
.videoArea {
  width: 90%;
  height: 500px;
  border: 1px solid #000;
}
.daysDivJoinMobail {
  display: none;
}
@media only screen and (max-width: 360px) {
  .ongoing {
    text-align: left;
    position: relative;
    left: 0px;
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 21px;
    text-transform: capitalize;
  }
  .joinNowDisplay {
    display: flex;
    flex-direction: row;
    flex-flow: column-reverse;
  }
  .daysDivJoinMobail {
    display: block;
  }
  .videoArea {
    width: 100%;
    height: 152px;
    border: 1px solid #000;
  }
  .methodology {
    text-align: left;
    width: 100%;
    position: relative;
    top: 10px;
    left: 0px;
    color: #8d8989;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 12.8px;
  }
  .daysDivJoin {
    display: none;
    position: relative;
    left: 0px;
    top: 20px;
    // line-height:20px;
  }
  .daysJoin {
    text-align: left;
    padding-bottom: 2px;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    line-height: 14px;
  }
  .joinNow {
    width: 100%;
    height: auto;
    margin-top: 0px;
  }
  .batchname {
    color: #1313a1;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 23px;
  }
  .joinDiv {
    position: relative;
    top: 20px;
    left: 0px;
    width: 100%;
  }
  .joinn {
    width: 50%;
    height: 50px;
    border: 2px solid #92dbe4;
    border-radius: 5px;
    background-color: #fff;
    color: blue;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
  }
  .hexCodeRight {
    align-items: center;
    background-color: #d0d9ea;
    border-radius: 15px;
    padding-left: 6px;
    padding-top: 4px;
    height: 25px;
    width: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 48.25%;
    position: relative;
  }

  .container {
    margin-left: 0px !important;
  }
  .ongoing {
    text-align: left;
    position: relative;
    left: 0px;
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 21px;
    text-transform: capitalize;
  }
  .joinNowDisplay {
    display: flex;
    flex-direction: row;
    flex-flow: column-reverse;
  }
  .daysDivJoinMobail {
    display: block;
  }
  .videoArea {
    width: 100%;
    height: 152px;
    border: 1px solid #000;
  }
  .methodology {
    text-align: left;
    width: 100%;
    position: relative;
    top: 10px;
    left: 0px;
    color: #8d8989;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 12.8px;
  }
  .daysDivJoin {
    display: none;
    position: relative;
    left: 0px;
    top: 20px;
    // line-height:20px;
  }
  .daysJoin {
    text-align: left;
    padding-bottom: 2px;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    line-height: 14px;
  }
  .joinNow {
    width: 100%;
    height: auto;
    margin-top: 0px;
  }
  .batchname {
    color: #1313a1;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 23px;
  }
  .joinDiv {
    position: relative;
    top: 20px;
    left: 0px;
    width: 100%;
  }
  .joinn {
    width: 50%;
    height: 50px;
    border: 2px solid #92dbe4;
    border-radius: 5px;
    background-color: #fff;
    color: blue;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
  }
  .hexCodeRight {
    align-items: center;
    background-color: #d0d9ea;
    border-radius: 15px;
    padding-left: 6px;
    padding-top: 4px;
    height: 25px;
    width: 25px;
  }
}
@media screen and (min-width:820px) and (max-width: 1180px) {
  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 117.25%;
    position: relative;
  }

  .container {
    margin-left: 0px !important;
  }
  .ongoing {
    text-align: left;
    position: relative;
    left: 0px;
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 21px;
    text-transform: capitalize;
  }
  .joinNowDisplay {
    display: flex;
    flex-direction: row;
    // flex-flow: column-reverse;
  }
  .daysDivJoinMobail {
    display: block;
  }
  .videoArea {
    width: 100%;
    height: 370px;
    border: 1px solid #000;
  }
  .methodology {
    text-align: left;
    width: 100%;
    position: relative;
    top: 10px;
    left: 0px;
    color: #8d8989;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 12.8px;
  }
  .daysDivJoin {
    display: none;
    position: relative;
    left: 0px;
    top: 20px;
    // line-height:20px;
  }
  .daysJoin {
    text-align: left;
    padding-bottom: 2px;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    line-height: 14px;
  }
  .joinNow {
    width: 100%;
    height: auto;
    margin-top: 0px;
  }
  .batchname {
    color: #1313a1;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 23px;
  }
  .joinDiv {
    position: relative;
    top: 20px;
    left: 0px;
    width: 100%;
  }
  .joinn {
    width: 50%;
    height: 50px;
    border: 2px solid #92dbe4;
    border-radius: 5px;
    background-color: #fff;
    color: blue;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
  }
  .hexCodeRight {
    align-items: center;
    background-color: #d0d9ea;
    border-radius: 15px;
    padding-left: 6px;
    padding-top: 4px;
    height: 25px;
    width: 25px;
  }
}
