// .footer {
//   position: relative;
//   top: 160px;
//   width: 100%;
//   height: 610px;
//   background-color: #f0f3f4;
// }

// .bannerContent {
//   position: absolute;
//   top: 50%;
//   left: 10%;
//   -webkit-transform: translate(-10%, -50%);
//   transform: translate(-10%, -50%);
//   width: 100%;
//   z-index: 2;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   text-align: center;
//   padding-right: 20px;
// }
.section1 {
  h1 {
    font-size: 25px !important;
  }
}

.sub-total {
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-left: 0px;
}

.btn,
.btn:hover {
  transition: 0.3s ease-in-out;
}

.btn-solid:hover {
  background-position: 100%;
  color: #000 !important;
  background-color: #fff;
}

.btn {
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
}

.fonts2 {
  font-size: 25px !important;
  font-family: "Raleway", sans-serif;
  font-weight: bolder;
  padding: 0px;
  padding-left: 100px;
  color: #fff;
  padding-top: 15px;
  margin-left: 50px;
  margin-bottom: 0px;
}

.btn-solid {
  padding: 13px 29px;
  color: #fff !important;
  letter-spacing: 0.05em;
  border: 2px solid #3a519d;
  background-image: linear-gradient(30deg, #3a519d 50%, transparent 0);
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: 0;
  // transition: background .3s ease-in-out;
}

.checkout_class {
  text-transform: capitalize;
}

.checkout-page {
  .checkout-title {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;

    h3 {
      color: #444;
      font-weight: 700;
    }
  }

  .checkout-form {
    .checkout-details {
      position: relative;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      padding: 40px;
    }

    // .check-out {
    //   // .form-group {
    //   //   &:last-child {
    //   //     // margin-bottom: -5px;
    //   //     // label {
    //   //     //   // margin-bottom: -5px;
    //   //     // }
    //   //   }
    //   // }
    // }
    .form-group {
      position: relative;
      margin-bottom: 25px;

      h3 {
        color: #444;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .field-label {
        line-height: 24px;
        text-transform: capitalize;
        color: #333333;
        margin-bottom: 10px;
        font-weight: 700;

        span {
          font-size: 16px;
          // color: $dark-font;
          font-weight: 600;
        }
      }

      // label {
      //   // color: $grey;
      // }
    }

    select {
      cursor: pointer;
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      -moz-appearance: none;
      // background: url("#{$assetspath}/dropdown.png") no-repeat 95%;
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="password"],
      &[type="tel"],
      &[type="number"],
      &[type="url"] {
        width: 100%;
        padding: 0 22px;
        height: 45px;
        border: 1px solid #dddddd;
      }
    }

    select,
    textarea {
      width: 100%;
      padding: 0 22px;
      height: 45px;
      border: 1px solid #dddddd;
    }
  }

  .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;

    label {
      position: relative;
      top: -1px;
      font-weight: normal;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
      // color: $font-color;
    }
  }

  .lower-content {
    margin-top: 30px;

    .order-column {
      margin-bottom: 40px;
    }
  }
}

.order-box {
  position: relative;
  margin-bottom: 20px;

  .title-box {
    position: relative;
    padding-bottom: 25px;
    color: #444;
    font-weight: 600;
    font-size: 22px;

    // border-bottom: 1px solid #ededed;
    // margin-bottom: 20px;
    span {
      position: relative;
      width: 35%;
      // float: right;
      line-height: 1.2em;
    }
  }

  .qty {
    position: relative;
    border-bottom: 1px solid #ededed;
    margin-bottom: 30px;

    li {
      position: relative;
      display: block;
      font-size: 15px;
      color: #444;
      line-height: 20px;
      margin-bottom: 20px;

      span {
        // float: right;
        font-size: 18px;
        line-height: 20px;
        color: #232323;
        font-weight: 400;
        width: 35%;
      }
    }
  }

  .sub-total {
    position: relative;
    // border-bottom: 1px solid $round-border;
    margin-bottom: 10px;

    li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
      margin-bottom: 20px;
      width: 100%;

      .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #00abed;
        font-weight: 400;
        padding-left: 20px;
        // width: 35%;
        // float: right;
      }
    }

    .shopping-option {
      label {
        position: relative;
        font-size: 16px;
        line-height: 32px;
        padding-left: 10px;
        // color: $grey6;
      }
    }

    .shipping {
      width: 35%;
      float: right;
    }
  }

  .total {
    position: relative;
    margin-bottom: 40px;
    padding-left: 0px;

    li {
      position: relative;
      display: block;
      font-weight: 400;
      // color: $font-color;
      line-height: 20px;
      margin-bottom: 10px;
      font-size: 18px;

      .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #00abed;
        font-weight: 400;
      }

      span {
        // float: right;
        font-size: 15px;
        line-height: 20px;
        color: #444;
        font-weight: 400;
        width: 35%;
        display: block;
      }
    }
  }
}

.payment-box {
  position: relative;

  .upper-box {
    position: relative;
  }

  .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;

    li {
      display: flex;
      margin-bottom: 15px;

      .radio-option {
        position: relative;

        label {
          position: relative;
          padding-left: 30px;
          text-transform: capitalize;
          // color: $grey6;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }

        input[type="radio"] {
          position: absolute;
          left: 0;
          top: 5px;
        }

        label {
          .small-text {
            position: relative;
            display: none;
            font-size: 15px;
            line-height: 25px;
            font-weight: 300;
            color: #666666;
            margin-top: 10px;
          }

          img {
            position: relative;
            display: block;
            max-width: 100%;
            margin-left: -30px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.stripe-section {
  text-transform: uppercase;
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;

  table {
    tbody {
      tr {
        td {
          &:first-child {
            padding-right: 15px;
          }
        }
      }
    }
  }

  h5 {
    font-weight: 700;
  }

  .content {
    h5 {
      color: #4f4f4f;
      font-weight: 400;
    }

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid #f1f1f1;

        td {
          padding: 8px 0;
          color: #9a9a9a;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}

.stripe-section {
  .content {
    table {
      tr {
        td {
          &:first-child {
            text-align: right;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
  .order-box .sub-total {
    position: relative;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  .checkout-page .checkout-form .checkout-details {
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .order-box .total {
    position: relative;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .btn {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    border-radius: 0;
  }

  .btn-solid {
    padding: 7px 9px;
    color: #fff !important;
    letter-spacing: 0.05em;
    border: 2px solid #3a519d;
    background-image: linear-gradient(30deg, #3a519d 50%, transparent 0);
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
  }

  .order-box .total li span {
    font-size: 15px;
    line-height: 20px;
    color: #444;
    font-weight: 400;
    width: 100%;
    display: block;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .order-box .total li span {
    font-size: 15px;
    line-height: 20px;
    color: #444;
    font-weight: 400;
    width: 67%;
    display: block;
    margin-left: 24px;
  }
  .checkout-page .checkout-form .checkout-details {
    margin-top: 10px;
  }
}
@media only screen and (width: 768px) {
  .order-box .total li span {
    margin-left: 20px;
    width: 65%;
  }
  .checkout-page .checkout-form .checkout-details {
    margin-top: 10px;
  }
}
