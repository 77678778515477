.Web {
  display: block;
}

.Responsive {
  display: none;
}

.section1 {
  background-image: url("../images/sub_back.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-size: 25px !important;
    font-family: "Raleway", sans-serif;
    font-weight: bolder;
    padding: 0px;
    padding-left: 100px;
    color: #fff;
    padding-top: 21px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .titleName {
    font-weight: bolder;
    font-size: 18px;
    text-align: left;
    color: #fff;
    line-height: 30px;
    padding-left: 100px;
    margin-left: 0px;
    margin-top: 0px;
  }
}

.section2 {
  .attemptCourseDetail {
    border-bottom: 1px solid #eeeeee;
    height: auto;
    width: 96%;
  }

  .attemptCourseDetail2 {
    border-bottom: 1px solid #eeeeee;
    font-family: Nunito, sans-serif;
    height: 60px;
    width: 96%;

    padding-top: 10px;

    .showingDetail {
      font-size: 17px;
      font-weight: 600;
    }

    .batchButtonDetail {
      background-color: #139413;
      border: none;
      border-radius: 5px;
      color: #fff;
      padding: 7px 20px;
    }
  }

  .attemptCourseDetail3 {
    background-color: #f1f8ff;
    border: 1px solid #edeaea;
    height: 335px;
    width: 96%;
    margin-top: 31px;

    .flex {
      display: flex;
      font-family: Nunito, sans-serif;
      padding-bottom: 10px;
    }
  }

  .attemptCourseDetail4 {
    border-bottom: 1px solid #e0e0e0;
    // padding-bottom: 10px;
    margin-top: 20px;
    width: 96%;

    .activate {
      width: 100%;

      .font {
        font-family: Nunito, sans-serif;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 12px;
        font-weight: 400;
      }

      .activationProcess {
        color: #3a529d;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }

  .attemptCourseDetail5 {
    height: 100px;
    width: 96%;
    margin-left: 10px !important;

    .systemReq {
      font-family: Raleway, sans-serif;
      font-weight: 700;
      font-size: 20px;
    }

    .activate2 {
      width: 100%;
      padding-left: 0px;

      .font2 {
        font-family: Nunito, sans-serif;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
      }
    }

    .downloadArea {
      background-color: #f5f6f7;
      border: 2px solid #d9d6d6;
      border-radius: 5px;
      cursor: pointer;
      font-family: Raleway, sans-serif;
      height: 55px;
      width: 285px;
      // margin-left: 400px !important;
      // top: -100px;


      .pdfImg {
        height: 45px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 3px;
        width: 45px;
      }

      .lectureIndex {
        color: #3a529d;
        font-size: 18px;
        font-weight: 600;
        padding-top: 13px;
        margin-left: -3px;
      }
    }
  }

  .attemptCourseDetail6 {
    width: 96%;
    // margin-left: px;

    .classroomBenefits {
      border: none;
      border-radius: 5px 5px 0 0;
      padding: 12px 25px;
      background-color: #3a529d;
      color: #fff;
      font-family: Nunito, sans-serif;
      font-size: 15px;
      font-weight: 600;
    }

    .aboutFac {
      background-color: #e8eeff;
      color: #717171;
      font-family: Nunito, sans-serif;
      font-size: 15px;
      font-weight: 600;
      border: none;
      border-radius: 5px 5px 0 0;
      padding: 12px 25px;
      font-family: Nunito, sans-serif;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .attemptCourseDetail7 {
    width: 96%;
    border: 1px solid #eeeeee;
    font-family: Nunito, sans-serif;
    height: auto;
    padding: 10px;
    background-color: #fcfdff;

    .systemReq2 {
      font-family: Nunito, sans-serif;
      font-size: 20px;
      font-weight: 900;
      padding-left: 40px;
      padding-top: 15px;
    }

    .flexSmart {
      font-family: Nunito, sans-serif;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      padding-bottom: 12px;
    }

    .aboutFaculty {
      margin-bottom: 20px;
      margin-left: 40px;
    }

    p {
      // margin-top: -20px;
      margin-left: 20px;
    }
  }
}

.section3 {
  .rightSide {
    margin-top: 40px;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.48);
    position: absolute;
    z-index: 99;
    width: 292px;
    height: 166px;
    left: -1px;
    justify-content: center;
    align-items: center;
    top: 40px;
  }
  .overlayStart {
    z-index: 9999;
    color: red;
    font-size: 18px;
    font-weight: bold;
    background: #fff;
    padding: 30px;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
    margin: 192px 0px;
    line-height: 27px;
    text-align: center;
  }
  .blueBtnDetail {
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    // margin-left: 20px;

    width: 140px;
    z-index: 99;
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-family: Nunito, sans-serif;
  }

  .grayBtnDetail {
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    margin-left: 20px;

    width: 120px;
    z-index: 9;
    background-color: #fff;
    border: 2px solid #3a529d;
    color: #000;
  }

  .grayBtnDetail1 {
    background-color: #3a529d;
    color: #fff;
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    margin-left: 20px;

    width: 120px;
    z-index: 9;
    border: 1px solid #3a529d;
  }

  .blueBtnDetail1 {
    background-color: #fff;
    border: 2px solid #3a529d;
    border-radius: 5px;
    color: #000;
    font-family: Raleway, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    // margin-left: 20px;
    width: 140px;
    z-index: 99;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .variants {
    // border: 1px solid ##f8f8f8;
    // border-radius: 5px;
    // height: auto;
    // padding-bottom: 25px;
    // padding-left: 25px;
    // width: 100%;
    position: relative;
    top: 30px;
    border: 1px solid #eeeeee;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 25px;
    padding-bottom: 25px;

    .vidArea {
      //   border-radius: 5px;
      //   height: 185px;
      //   margin-bottom: 5px;
      //   margin-left: 6px;
      //   margin-top: 28px;
      //   width: 90%;
      width: 80%;
      height: 165px;
      margin-top: -28px;
      margin-left: 6px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .deletePrice {
      color: red;
      font-size: 16px;
      font-weight: 500;
    }

    .offPrice {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .rupeeColor {
      font-size: 16px;
      font-weight: 500;
      color: #139413;
    }

    .pricesNote {
      font-family: Nunito, sans-serif;
      margin-left: 6px;
      width: 90%;
      border-bottom: 1px solid #d9d7d7;
      padding-bottom: 20px;
    }

    .oprSystem {
      border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: Nunito, sans-serif;
      margin-left: 6px;
      width: 90%;

      h4 {
        font-weight: 700;
      }

      .varName {
        font-size: 15px;
        font-weight: 600;
        margin-right: 20px;
        padding: 6px;
      }
    }

    .buyBtnArea {
      margin-top: 30px;
      text-align: center;
      font-family: Nunito, sans-serif;

      .buyCourseBtn {
        background-color: #3a529d;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 47px;
        width: 160px;
      }
    }

    .applyBtnArea {
      margin-top: 20px;
      padding-bottom: 25px;
      text-align: center;

      .applyCoupon {
        border-bottom: 1px solid #3a529d;
        color: #3a529d;
        font-family: Nunito, sans-serif;
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 7px;
        cursor: pointer;
      }
    }
  }
}
.couponWrapper {
  margin-top: 15px;
}
.verifyBtn {
  margin-left: 10px;
  padding: 7px 10px;
  background: #3a529d;
  border: none;
  color: #fff;
  width: 23%;
  border-radius: 5px;
}
.couponSuccessMessage {
  color: green;
  text-transform: capitalize;
  padding-top: 5px;
}
.couponFailMessage {
  color: red;
  text-transform: capitalize;
  padding-top: 5px;
}
@media only screen and (min-width: 360px) and (max-width:768px) {
  .Web {
    display: none;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.48);
    position: absolute;
    z-index: 99;
    width: 322px;
    height: 166px;
    left: 22px;
    justify-content: center;
    align-items: center;
  }
  .Responsive {
    display: block;
  }

  .section1 {
    background-image: url("../images/sub_back.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      font-size: 19px !important;
      padding-left: 30px;
      margin-left: -3px;
    }

    .titleName {
      font-size: 15px;
      padding-left: 30px;
      line-height: 23px;
      margin-left: -3px;
    }
  }

  .details {
    text-align: left;
    position: relative;
    left: 0px;
  }

  .courseNameDetail {
    position: relative;
    top: 20px;
    font-weight: 600;
    font-size: 14px;
    left: 16px;
    top: -31px;
    color: #000;
    line-height: 30px;
  }

  .courseNameDetail {
    position: relative;
    top: 20px;
    font-weight: 600;
    font-size: 14px;
    /* text-align: left; */
    left: 16px;
    top: -43px;
    color: #fff;
    line-height: 30px;
  }

  .imgHeight {
    height: 70px;
  }

  .attemptCourse2 {
    text-align: center;
    position: relative;
    top: 0px !important;
    align-content: center;
  }

  .attemptCourseDetail {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #dedbdb;
  }
}
